// custom typefaces
import "typeface-montserrat";
import "typeface-merriweather";
import "./src/styles/font.css";

// normalize CSS across browsers
import "./src/styles/normalize.css";

// import tailwindcss styles
import './src/styles/global.css'

// Add custom class name to body for certain routes
export const onRouteUpdate = ({ location, prevLocation }) => {
  let bodyClass = '';
  switch (location.pathname) {
    case '/':
      bodyClass = 'bg-secondary';
      break;
    default:
      return;
  }
  if (bodyClass){
    document.body.className = bodyClass;
  }
};

// in gastby-browser.js
// export const shouldUpdateScroll = ({
//     routerProps: { location },
//     getSavedScrollPosition,
//   }) => {
//     const { pathname } = location
//
//     console.log(pathname, getSavedScrollPosition)
//     // list of routes for the scroll-to-top-hook
//     const scrollToTopRoutes = [`/privacy-policy`, `/page-2`]
//     // if the new route is part of the list above, scroll to top (0, 0)
//     if (scrollToTopRoutes.indexOf(pathname) !== -1) {
//       window.scrollTo(0, 0)
//     }
//
//     return false
//   }